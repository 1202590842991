import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import software from "../../images/new-ppc/Intersect.svg"
import IntersectBlue from "../../images/new-ppc/IntersectBlue.svg"
import IntersectYellow from "../../images/new-ppc/IntersectYellow.svg"
import vector4 from "../../images/new-ppc/section2/vector1.png"
import vector2 from "../../images/new-ppc/section2/vector2.png"
import vector3 from "../../images/new-ppc/section2/vector3.png"
import software2 from "../../images/new-ppc/software2.svg"
import vector1 from "../../images/new-ppc/vector3.png"
import * as styles from "./SoftwareArray.module.scss"

const SoftwareArray = ({ strapiData }) => {
  const bgImages = [
    {
      image: IntersectYellow,
    },
    {
      image: IntersectBlue,
    },
    {
      image: software,
    },
    {
      image: software,
    },
    {
      image: IntersectYellow,
    },
    {
      image: IntersectBlue,
    },
  ]
  return (
    <div className={styles.softArray}>
      <div className={styles.leftVector1}>
        <img decoding="async" loading="lazy" src={vector1} alt="vector" />
      </div>
      <div className={styles.leftVector2}>
        <img decoding="async" loading="lazy" src={vector2} alt="vector" />
      </div>
      <div className={styles.rightVector1}>
        <img decoding="async" loading="lazy" src={vector3} alt="vector" />
      </div>
      <div className={styles.rightVector2}>
        <img decoding="async" loading="lazy" src={vector4} alt="vector" />
      </div>
      <Container>
        <div className={styles.heading}>
          <h2>Array of Software Development And Consulting Services</h2>
          <p>
            Avoid bumpy rides in your design to the implementation process with
            InvoZone’s fail-safe expertise and knowledge.
          </p>
        </div>
        <Row
          className="gap-30"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
        >
          {strapiData?.cards &&
            strapiData?.cards.map((e, i) => (
              <Col lg={4} md={6} xs={12} key={i} style={{ display: "flex" }}>
                <div className={styles.card}>
                  <div className={styles.softwareBgRe}>
                    {" "}
                    <img
                      decoding="async"
                      loading="lazy"
                      src={software2}
                      alt="software2"
                    />
                  </div>
                  <div className={styles.softwareBg}>
                    <img
                      decoding="async"
                      loading="lazy"
                      alt="bacground"
                      src={bgImages[i]?.image}
                    />
                  </div>
                  <div className={styles.topCard}>
                    {
                      <img
                        src={e?.image1[0]?.localFile?.publicURL}
                        alt={e?.image1[0].alternativeText}
                        decoding="async"
                        loading="lazy"
                      />
                    }
                    <h3>{e?.title}</h3>
                  </div>
                  <div
                    className={styles.body}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </Col>
            ))}
        </Row>
        <div className={styles.softwareBtn}>
          <Link to={strapiData.buttons[0].url} variant="light mt-3 mt-xl-0">
            {strapiData.buttons[0].title}
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default SoftwareArray
