import React from "react"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import tool from "../../images/new-ppc/arrow-left.png"
import top2 from "../../images/new-ppc/test/asd1.png"
import top from "../../images/new-ppc/test/asd2.png"
import bottom2 from "../../images/new-ppc/test/asd3.png"
import bottom from "../../images/new-ppc/test/asd4.png"
import * as styles from "./Testimonials.module.scss"
import "./TechSlider.scss"

const Testimonials = ({ strapiData }) => {
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    infinite: false,
    arrows: false,
    dots: true,
    dotsClass: "new-dots",
    // fade: true,
    pauseOnHover: false,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Testimonials}>
      <Container>
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
        <div>
          <Slider {...settings}>
            {strapiData?.cards?.map((e, i) => (
              <div className={`${styles.card}`} key={i}>
                <img
                  decoding="async"
                  loading="lazy"
                  src={tool}
                  alt="border"
                  className={styles.corner}
                />
                <img
                  decoding="async"
                  loading="lazy"
                  alt="border"
                  src={top}
                  className={styles.commo1}
                />
                <img
                  decoding="async"
                  alt="border"
                  loading="lazy"
                  src={bottom2}
                  className={styles.commo2}
                />
                <img
                  decoding="async"
                  alt="border"
                  loading="lazy"
                  src={top2}
                  className={styles.box}
                />
                <img
                  decoding="async"
                  alt="border"
                  loading="lazy"
                  src={bottom}
                  className={styles.top}
                />
                <div className={styles.cardBody}>
                  <p
                    className={styles.description}
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                  <h3 className={styles.title}> {e?.title}</h3>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default Testimonials
