import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import vector5 from "../../images/new-ppc/topVector.png"
import vector3 from "../../images/new-ppc/vector1.png"
import vector4 from "../../images/new-ppc/vector2.png"
import vector1 from "../../images/new-ppc/vector3.png"
import vector2 from "../../images/new-ppc/vector5.png"
import * as styles from "./Banner.module.scss"
import PpcForm from "./PpcForm"

const Banner = ({ strapiData }) => {
  return (
    <div className={styles.section}>
      <div className={styles.leftVector1}>
        <img decoding="async" loading="lazy" src={vector1} alt="vector" />
      </div>
      <div className={styles.leftVector2}>
        <img decoding="async" loading="lazy" src={vector2} alt="vector" />
      </div>
      <div className={styles.rightVector1}>
        <img decoding="async" loading="lazy" src={vector3} alt="vector" />
      </div>
      <div className={styles.rightVector2}>
        <img decoding="async" loading="lazy" src={vector4} alt="vector" />
      </div>
      <div className={styles.rightVector3}>
        <img decoding="async" loading="lazy" src={vector5} alt="vector" />
      </div>
      <Container>
        <Row>
          <Col lg={6}>
            <div className={styles.contentBox}>
              <h1 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <div
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div>
              <div data-aos="fade-up">
                <div className={styles.developerCycle}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map(
                      (item, index) =>
                        index < 2 && (
                          <div className={styles.projectCycle}>
                            <div className={styles.content}>
                              <div className={styles.cycleCircle}>
                                <img
                                  src={item?.image1[0].localFile.publicURL}
                                  alt={item?.title}
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div>
                              <p>{item?.title}</p>
                            </div>
                          </div>
                        )
                    )}
                </div>

                <div className={`${styles.developerCycle} pl-lg-5`}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map(
                      (item, index) =>
                        index > 1 &&
                        index < 4 && (
                          <div className={styles.projectCycle}>
                            <div className={styles.content}>
                              <div className={styles.cycleCircle}>
                                <img
                                  src={item?.image1[0].localFile.publicURL}
                                  alt={item?.title}
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div>
                              <p>{item?.title}</p>
                            </div>
                          </div>
                        )
                    )}
                </div>
                <div className={styles.developerCycle}>
                  {strapiData?.cards &&
                    strapiData?.cards?.map(
                      (item, index) =>
                        index > 3 && (
                          <div className={styles.projectCycle}>
                            <div className={styles.content}>
                              <div className={styles.cycleCircle}>
                                <img
                                  src={item?.image1[0].localFile.publicURL}
                                  alt={item?.title}
                                  decoding="async"
                                  loading="lazy"
                                />
                              </div>
                              <p>{item?.title}</p>
                            </div>
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.bg2}>
              <PpcForm />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Banner
