// extracted by mini-css-extract-plugin
export var b = "PpcForm-module--b--cc47d";
export var button = "PpcForm-module--button--7666d";
export var check = "PpcForm-module--check--acf9f";
export var checkmark = "PpcForm-module--checkmark--19bec";
export var errorTxt = "PpcForm-module--errorTxt--389c5";
export var form = "PpcForm-module--form--c5524";
export var formInput = "PpcForm-module--formInput--f910c";
export var formTitle = "PpcForm-module--formTitle--77bc1";
export var ppcForm = "PpcForm-module--ppcForm--b95dc";
export var rotate = "PpcForm-module--rotate--fd7e2";
export var s = "PpcForm-module--s--a7bdb";
export var selectInput = "PpcForm-module--selectInput--f9787";
export var topSection = "PpcForm-module--topSection--20439";
export var vector1 = "PpcForm-module--vector1--9266d";
export var vector2 = "PpcForm-module--vector2--538ab";
export var vector3 = "PpcForm-module--vector3--da18d";
export var vector4 = "PpcForm-module--vector4--a9854";
export var vector5 = "PpcForm-module--vector5--c3227";