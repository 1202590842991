// extracted by mini-css-extract-plugin
export var body = "SoftwareArray-module--body--2c24a";
export var button = "SoftwareArray-module--button--caeb3";
export var card = "SoftwareArray-module--card--93fe3";
export var heading = "SoftwareArray-module--heading--6b802";
export var leftVector1 = "SoftwareArray-module--leftVector1--76716";
export var leftVector2 = "SoftwareArray-module--leftVector2--f4c24";
export var rightVector1 = "SoftwareArray-module--rightVector1--bac3d";
export var rightVector2 = "SoftwareArray-module--rightVector2--b7431";
export var softArray = "SoftwareArray-module--softArray--3da77";
export var softwareBg = "SoftwareArray-module--softwareBg--720f6";
export var softwareBgRe = "SoftwareArray-module--softwareBgRe--f9189";
export var softwareBtn = "SoftwareArray-module--softwareBtn--18b38";
export var topCard = "SoftwareArray-module--topCard--f16d2";