// extracted by mini-css-extract-plugin
export var card = "Info-module--card--3befb";
export var cardData = "Info-module--cardData--772c0";
export var cardGroup = "Info-module--cardGroup--b25bd";
export var info = "Info-module--info--c5498";
export var infoContent = "Info-module--infoContent--4ac62";
export var infoImage = "Info-module--infoImage--09336";
export var leftVector1 = "Info-module--leftVector1--39f40";
export var preferenceBtn = "Info-module--preferenceBtn--9e767";
export var preferenceCard = "Info-module--preferenceCard--43189";
export var prefernecebg = "Info-module--prefernecebg--117db";
export var rightVector1 = "Info-module--rightVector1--d8f43";