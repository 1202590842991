import React, { useEffect, useRef, useState } from "react"
import Container from "react-bootstrap/Container"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./TechSlider2.module.scss"
import "./TechSlider.scss"

const TechSlider = ({ techSlider2, techSlider3 }) => {
  const tech = [...techSlider2.cards, ...techSlider3.cards]

  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)
  const [current_3, setCurrent_3] = useState(0)

  useEffect(() => {
    // console.log("current", current, tech.length, tech.length - 4 );
    ;(current === tech.length - 4 ||
      current_1 === tech.length - 3 ||
      current_2 === tech.length - 2 ||
      current_3 === tech.length - 1) &&
      setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 7000)
  }, [current, current_1, current_2, current_3])

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    //autoplaySpeed: 100,
    infinite: false,
    dotsClass: "new-dots",
    arrows: false,
    dots: true,
    beforeChange: (current, next) => setCurrent(next),
    pauseOnHover: false,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          dots: false,
          autoplay: true,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          speed: 3000,
          dots: false,
          autoplay: true,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          speed: 3000,
          dots: false,
          autoplay: true,
          beforeChange: (current, next) => setCurrent_3(next),
        },
      },
    ],
  }

  return (
    <div className={`${styles.sliderMain} sliderTech2`}>
      <Container>
        {" "}
        <h2 dangerouslySetInnerHTML={{ __html: techSlider2?.title }} />
      </Container>
      <Slider className={`mt-4 slider2`} {...settings} ref={slide1}>
        {tech?.map((e, i) => (
          <div className={styles.box} key={i}>
            <img
              decoding="async"
              loading="lazy"
              src={e?.image1[0] && e?.image1[0]?.localFile?.publicURL}
              alt={e?.title}
            />
            <h3>{e?.title}</h3>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default TechSlider
