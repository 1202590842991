// extracted by mini-css-extract-plugin
export var Testimonials = "Testimonials-module--Testimonials--103f8";
export var box = "Testimonials-module--box--b5019";
export var card = "Testimonials-module--card--884ad";
export var cardBody = "Testimonials-module--cardBody--6325e";
export var commo1 = "Testimonials-module--commo1--2de2a";
export var commo2 = "Testimonials-module--commo2--1ddd6";
export var corner = "Testimonials-module--corner--ea185";
export var description = "Testimonials-module--description--24007";
export var title = "Testimonials-module--title--487c4";
export var top = "Testimonials-module--top--00b5c";