// extracted by mini-css-extract-plugin
export var BottomCards = "BottomCards-module--BottomCards--35007";
export var back = "BottomCards-module--back--9a71f";
export var bottomCard = "BottomCards-module--bottomCard--61dbf";
export var button = "BottomCards-module--button--30d76";
export var cardDec = "BottomCards-module--cardDec--80335";
export var cardTitle = "BottomCards-module--cardTitle--f3a67";
export var content = "BottomCards-module--content--999a3";
export var front = "BottomCards-module--front--c9846";
export var headingCard = "BottomCards-module--headingCard--7e6a7";
export var headingCard2 = "BottomCards-module--headingCard2--45fdf";
export var hover = "BottomCards-module--hover--5aeb7";
export var index2 = "BottomCards-module--index2--c03c6";
export var index3 = "BottomCards-module--index3--1fac1";
export var innerCard = "BottomCards-module--innerCard--4ff24";
export var leftVector1 = "BottomCards-module--leftVector1--e0a0d";
export var mainCard = "BottomCards-module--mainCard--dd7a7";
export var rightVector1 = "BottomCards-module--rightVector1--9908b";
export var ticksSections = "BottomCards-module--ticksSections--f1b83";