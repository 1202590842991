import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import vector4 from "../../images/new-ppc/sectionlast/vector1.png"
import vector1 from "../../images/new-ppc/sectionlast/vector2.png"
import vector3 from "../../images/new-ppc/sectionlast/vector3.png"
import vector2 from "../../images/new-ppc/sectionlast/vector4.png"
import * as styles from "./LastSection.module.scss"
import PpcForm from "./PpcForm"

const LastSection = ({ strapiData }) => {
  return (
    <div className={styles.last}>
      <div className={styles.leftVector1}>
        <img decoding="async" loading="lazy" src={vector1} alt="vector" />
      </div>
      <div className={styles.leftVector2}>
        <img decoding="async" loading="lazy" src={vector2} alt="vector" />
      </div>
      <div className={styles.rightVector1}>
        <img decoding="async" loading="lazy" src={vector3} alt="vector" />
      </div>
      <div className={styles.rightVector2}>
        <img decoding="async" loading="lazy" src={vector4} alt="vector" />
      </div>
      <Container>
        <Row className="gap-30 align-items-start">
          <Col md={12} lg={6} className="order-2 order-md-1">
            <PpcForm bottom={true} />
          </Col>
          <Col md={12} lg={6} className="order-1 order-md-2">
            <div className={styles.content}>
              <div className={styles.topSection}>
                <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />

                <div
                  dangerouslySetInnerHTML={{
                    __html: strapiData?.description?.description,
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default LastSection
