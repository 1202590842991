// extracted by mini-css-extract-plugin
export var BgColor = "WorkInspiration-module--BgColor--349f3";
export var Cards = "WorkInspiration-module--Cards--ba732";
export var InspirationHeading = "WorkInspiration-module--InspirationHeading--ef453";
export var WorkInspiration = "WorkInspiration-module--WorkInspiration--a51e6";
export var bg = "WorkInspiration-module--bg--68b1b";
export var bottomLine = "WorkInspiration-module--bottomLine--9f892";
export var cardsLogo = "WorkInspiration-module--cardsLogo--f8f2c";
export var hoverBg = "WorkInspiration-module--hoverBg--6281a";
export var hoverBtn = "WorkInspiration-module--hoverBtn--26f74";
export var hoverImg = "WorkInspiration-module--hoverImg--ef523";
export var hoverText = "WorkInspiration-module--hoverText--71806";
export var leftVector1 = "WorkInspiration-module--leftVector1--52723";
export var projectImg = "WorkInspiration-module--projectImg--be50a";
export var rightVector1 = "WorkInspiration-module--rightVector1--64087";
export var rightVector2 = "WorkInspiration-module--rightVector2--d4343";
export var rightVector3 = "WorkInspiration-module--rightVector3--ac4ca";
export var subTitle = "WorkInspiration-module--subTitle--9f64b";
export var text = "WorkInspiration-module--text--03150";