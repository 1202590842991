import { Link } from "gatsby"
import React from "react"
import Container from "react-bootstrap/Container"
import vector from "../../images/new-ppc/newbox.png"
import pre from "../../images/new-ppc/pre.png"
import preferenceBlue from "../../images/new-ppc/preferenceBlue.svg"
import preferenceYellow from "../../images/new-ppc/preferenceYellow.svg"
import prefernecebg from "../../images/new-ppc/prefernce.svg"
import * as styles from "./Info.module.scss"

const Info = ({ strapiData }) => {
  const bgImages = [
    {
      image: prefernecebg,
    },
    {
      image: preferenceYellow,
    },
    {
      image: preferenceBlue,
    },
  ]
  return (
    <div className={styles.info}>
      <div className={styles.leftVector1}>
        <img decoding="async" loading="lazy" src={pre} alt="vector" />
      </div>
      <div className={styles.rightVector1}>
        <img decoding="async" loading="lazy" src={vector} alt="vector" />
      </div>
      <Container>
        <div className=" align-items-start">
          <div className={styles.infoContent}>
            <h2
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              style={{ cursor: "default" }}
            />
            <div
              style={{ cursor: "default" }}
              dangerouslySetInnerHTML={{
                __html: strapiData?.description?.description,
              }}
            />
            <div
              className={styles.preferenceCard}
              data-aos="fade-up"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              {strapiData?.cards?.map((item, index) => (
                <div className={styles.cardGroup}>
                  <div className={styles.prefernecebg}>
                    {" "}
                    <img
                      decoding="async"
                      loading="lazy"
                      alt="background"
                      src={bgImages[index]?.image}
                    />
                  </div>
                  <div className={styles.cardData}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={item?.image1[0].localFile.publicURL}
                      alt={item?.image1[0].alternativeText}
                    />
                    <h3>{item?.title}</h3>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.preferenceBtn}>
              <Link to={strapiData.buttons[0]?.url}>
                {strapiData.buttons[0]?.title}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Info
