// extracted by mini-css-extract-plugin
export var bg2 = "Banner-module--bg2--31e41";
export var content = "Banner-module--content--43110";
export var contentBox = "Banner-module--contentBox--2a7df";
export var cycleCircle = "Banner-module--cycleCircle--d0b45";
export var developerCycle = "Banner-module--developerCycle--d5bb5";
export var leftVector1 = "Banner-module--leftVector1--ccf17";
export var leftVector2 = "Banner-module--leftVector2--eb798";
export var projectCycle = "Banner-module--projectCycle--6ce20";
export var rightVector1 = "Banner-module--rightVector1--4418d";
export var rightVector2 = "Banner-module--rightVector2--3712d";
export var rightVector3 = "Banner-module--rightVector3--8443d";
export var section = "Banner-module--section--f1be5";