import React from "react"
import Container from "react-bootstrap/Container"
import hover1 from "../../images/new-ppc/hover1.svg"
import hover2 from "../../images/new-ppc/hover2.svg"
import vector1 from "../../images/new-ppc/section4/vector1.png"
import vector2 from "../../images/new-ppc/section4/vector2.png"
import vector3 from "../../images/new-ppc/section4/vector3.png"
import vector4 from "../../images/new-ppc/test/testbg2.png"
import * as styles from "./WorkInspiration.module.scss"

function WorkInspiration({ strapiData }) {
  return (
    <div className={styles.WorkInspiration}>
      <div className={styles.leftVector1}>
        <img decoding="async" loading="lazy" src={vector1} alt="vector" />
      </div>
      <div className={styles.rightVector1}>
        <img decoding="async" loading="lazy" src={vector2} alt="vector" />
      </div>
      <div className={styles.rightVector2}>
        <img decoding="async" loading="lazy" src={vector3} alt="vector" />
      </div>
      <div className={styles.rightVector3}>
        <img decoding="async" loading="lazy" src={vector4} alt="vector" />
      </div>
      <Container>
        <div className={styles.InspirationHeading}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />

          <div className={styles.Cards}>
            <div
              className={styles.cardsLogo}
              data-aos="zoom-in"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              {strapiData?.cards[0]?.jsonData?.ppc?.map((item, index) => (
                <div className={styles.BgColor} key={index}>
                  <div className={styles.bg}></div>
                  <div className={styles.hoverText}>
                    <div className={styles.text}>
                      <h3>{item?.title}</h3>
                      <div className={styles.bottomLine}></div>
                      <p className={styles.subTitle}>{item?.category[0]}</p>
                    </div>
                    <div className={styles.hoverBtn}>
                      <button>{item?.tech[0]}</button>
                      <button>{item?.tech[1]}</button>
                    </div>
                  </div>
                  <div className={styles.hoverImg}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={hover1}
                      alt="hover"
                    />
                  </div>

                  <div className={styles.hoverBg}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={hover2}
                      alt="hover"
                    />
                  </div>

                  <div className={styles.projectImg}>
                    {" "}
                    <img
                      decoding="async"
                      loading="lazy"
                      src={item?.img}
                      alt="project"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default WorkInspiration
