import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import email from "../../images/new-ppc/call.svg"
import logo from "../../images/new-ppc/Logo.svg"
import phone from "../../images/new-ppc/phone.svg"
import * as styles from "./HeaderOld.module.scss"
const Header = () => {
  return (
    <header>
      <nav className={styles.header}>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col md={6}>
              <Link to={`/`} className={styles.logo}>
                <img
                  decoding="async"
                  loading="lazy"
                  src={logo}
                  alt="invozone-logo"
                />
              </Link>
            </Col>
            <Col md={6}>
              <div className={styles.content}>
                <div className={styles.box}>
                  <div className={styles.email}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={email}
                      alt="phone"
                    />
                  </div>
                  <div className={styles.boxConent}>
                    <a
                      href="mailto:contact@invozone.com"
                      style={{ color: "white" }}
                    >
                      contact@invozone.com
                    </a>
                  </div>
                </div>
                <div className={styles.box}>
                  <div className={styles.phone}>
                    <img
                      decoding="async"
                      loading="lazy"
                      src={phone}
                      alt="phone"
                    />
                  </div>
                  <div className={styles.boxConent}>
                    <a href="tel:+14156898530">+1 (415) 689-8530</a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </nav>
    </header>
  )
}
export default Header
