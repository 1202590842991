import AOS from "aos"
import "aos/dist/aos.css"
import { graphql } from "gatsby"
import React, { useEffect } from "react"
import Footer from "../../components/common/Footer"
import Banner from "../../components/ppc/Banner"
import BottomCards from "../../components/ppc/BottomCards"
import Info from "../../components/ppc/Info"
import LastSection from "../../components/ppc/LastSection"
import PpcLayout from "../../components/ppc/PpcLayoutOld"
import SoftwareArray from "../../components/ppc/SoftwareArray"
import TechSlider2 from "../../components/ppc/TechSlider2"
import Testimonials from "../../components/ppc/Testimonials"
import WorkInspiration from "../../components/ppc/WorkInspiration"

const ContactUsPage = ({ data }) => {
  // console.log(data)
  const banner = data?.strapiPage?.sections[0]
  const softArray = data?.strapiPage?.sections[1]
  const techslider2 = data?.strapiPage?.sections[2]
  const techslider3 = data?.strapiPage?.sections[3]
  const infopart = data?.strapiPage?.sections[4]
  const test = data?.strapiPage?.sections[5]
  const bcards = data?.strapiPage?.sections[6]
  const relay = data?.strapiPage?.sections[7]
  const work = data?.strapiPage?.sections[8]

  useEffect(() => {
    localStorage.setItem("popup", false)
  }, [])
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 150,
    })
  }, [])
  useEffect(() => {
    if (Object.keys(Tawk_API)?.length > 0 && Tawk_API?.hideWidget) {
      Tawk_API?.hideWidget()
    } else {
      Tawk_API.onLoad = function () {
        Tawk_API?.hideWidget()
      }
    }
    return () => {
      if (Tawk_API?.showWidget) {
        Tawk_API?.showWidget()
      }
    }
  })

  return (
    <PpcLayout>
      <Banner strapiData={banner} />
      <SoftwareArray strapiData={softArray} />
      <TechSlider2 techSlider2={techslider2} techSlider3={techslider3} />
      <WorkInspiration strapiData={work} />
      <Info strapiData={infopart} />
      <Testimonials strapiData={test} />
      <BottomCards strapiData={bcards} />
      <LastSection strapiData={relay} />
      <Footer ppc={true} />
    </PpcLayout>
  )
}

export const query = graphql`
  query {
    strapiPage(slug: { eq: "ppc" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          image2 {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          buttons {
            url
            title
          }
          jsonData {
            ppc {
              category
              img
              tech
              title
            }
          }
        }
        secImages {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`

export default ContactUsPage

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="robots" content="noindex, nofollow"></meta>
      <meta name="googlebot" content="noindex, nofollow"></meta>
    </>
  )
}
