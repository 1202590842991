import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import lines from "../../images/new-ppc/lines.png"
import vector2 from "../../images/new-ppc/section7/arrow.png"
import vector1 from "../../images/new-ppc/section7/box.png"
import * as styles from "./BottomCards.module.scss"

const BottomCards = ({ strapiData }) => {
  return (
    <div className={styles.BottomCards}>
      <div className={styles.leftVector1}>
        <img decoding="async" loading="lazy" src={vector1} alt="vector" />
      </div>

      <div className={styles.rightVector1}>
        <img decoding="async" loading="lazy" src={vector2} alt="vector" />
      </div>

      <Container>
        <h2
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          className={styles.headingCard2}
        />
        <Row
          data-aos="zoom-in"
          data-aos-easing="linear"
          data-aos-duration="1000"
          className={`gap-30 align-items-center`}
        >
          <Col md={12} lg={12} xl={6} className="order-2 order-md-1">
            <Row className="gap-30 p-0 mt-5 mt-lg-0">
              {strapiData?.cards?.map((e, i) => (
                <Col md={6} key={i}>
                  <div className={styles.mainCard}>
                    <div
                      className={`${styles.innerCard} ${
                        i === 1 ? styles.index2 : i === 2 ? styles.index3 : ""
                      }`}
                    >
                      <div className={styles.front}>
                        <div className={`${styles.bottomCard} `}>
                          <img
                            decoding="async"
                            loading="lazy"
                            src={lines}
                            alt={e?.title}
                          />
                          <p className={styles.cardTitle}>{e?.title}</p>
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e?.description?.description,
                            }}
                          />
                        </div>
                      </div>
                      <div className={styles.back}>
                        <div className={styles.bottomCard}>
                          <img
                            decoding="async"
                            loading="lazy"
                            src={lines}
                            alt={e?.title}
                          />
                          <p className={styles.cardTitle}>{e?.title}</p>
                          <p
                            className={styles.cardDec}
                            dangerouslySetInnerHTML={{
                              __html: e?.subTitle,
                            }}
                          />
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e?.description?.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={12} lg={12} xl={6} className="order-1 order-md-2">
            <div className={styles.content}>
              {/* <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} /> */}
              <p
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
                className={styles.headingCard}
              />

              <p>{strapiData?.subTitle}</p>
              <div
                className={styles.ticksSections}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BottomCards
