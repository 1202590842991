import React from "react"
import Header from "./HeaderOld"
const PpcLayout = ({ children }) => {
  return (
    <div style={{ backgroundColor: `#FAFDFF` }}>
      <Header />
      <main>{children}</main>
    </div>
  )
}
export default PpcLayout
